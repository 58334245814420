<template>

</template>


<script>
export default {
  name: 'Head',
  props: ['initData'],
  data(){
    // const status = this.globalData.init.status
    // const contents = this.globalData.contents.pages[status]
    // const title = contents.head.title
    // document.querySelector('head title').innerHTML = title
    return{

    }
  },
  computed:{
    // status(){
    //   return this.globalData.init.status
    // },
    // headTitle(){
    //   return this.globalData.contents.pages[this.status].head.title
    // }
  },
  watch:{
    // headTitle(newValue){
    //   document.querySelector('head title').innerHTML = newValue
    // }
  }
}
</script>


<style>

</style>